import React from "react";
import PropTypes from "prop-types";
import OrderItem from "Components/Cards/OrderItem";
import Package from "Components/Cards/Package";
import { ReactComponent as ItemIcon } from "Assets/Icons/Item.svg";
import { ReactComponent as DeliveryIcon } from "Assets/Icons/Delivery.svg";

const Items = ({ data }) => (
    <React.Fragment>
        {data.entries && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <ItemIcon />
                </div>
                Order Items
            </div>
            {data.entries.map((entry, i) => <OrderItem key={`order-item-${i}`} data={entry}/>)}
        </div>}
        {data.listPackageDetails.length > 0 && <div className="order-card">
            <div className="header">
                <div className="icon">
                    <DeliveryIcon />
                </div>
                Packages
            </div>
            {data.listPackageDetails.map((entry, i) => <Package key={`package-${i}`} data={entry}/>)}
        </div>}
    </React.Fragment>
);

Items.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Items;