import React, { useState } from "react";
import moment from "moment";
import OrderRefund from "Components/OrderRefund";
import OrderReplace from "Components/OrderReplace";
import { ReactComponent as Chevron } from "Assets/Icons/Chevron_Right.svg";
import { ReactComponent as Entry } from "Assets/Icons/Entry.svg";

const Consignment = ({ data, code, items }) => {
    const [hidden, setHidden] = useState(true);
    const [refundActive, setRefundActive] = useState(false);
    //const [replaceActive, setReplaceActive] = useState(false);
    
    const onRefund = (e) => {
        if(e) {
            e.preventDefault();
        }
        refundActive ? setRefundActive(false) : setRefundActive(true);
    };

    // const onReplace = (e) => {
    //     if(e) {
    //         e.preventDefault();
    //     }
    //     replaceActive ? setReplaceActive(false) : setReplaceActive(true);
    // };

    const onHide = () => {
        setHidden(hidden ? false : true);
    };

    return (
        <div className="container">
            {refundActive && <OrderRefund isActive={refundActive} order={code} callback={onRefund} entries={data.entries} items={items} />}
            {/* {replaceActive && <OrderReplace isActive={replaceActive} order={code} callback={onReplace} entries={data.entries} items={items} />} */}
            {data.entries && data.entries.length > 0 && data.status === "SHIPPED" &&<div className="inner-header">
                <div className="icon">
                    <Entry />
                </div>
                <button onClick={onRefund}>Refund</button>
                {/* <button onClick={onReplace}>Replace</button> */}
            </div>}
            {data.typeOfOrder &&
                <div className="content">
                    <span>Type</span>
                    <div>{data.typeOfOrder}</div>
                </div>
            }
            {data.carrier &&
                <div className="content">
                    <span>Carrier</span>
                    <div>{data.carrier}</div>
                </div>
            }
            {data.shippingDate &&
                <div className="content">
                    <span>Shipping Date</span>
                    <div>{moment(new Date(data.shippingDate)).format("YYYY-MM-DD HH:mm:ss")}</div>
                </div>
            }
            {data.status &&
                <div className="content">
                    <span>Status</span>
                    <div>{data.status}</div>
                </div>
            }
            {data.warehouseCode &&
                <div className="content">
                    <span>Warehouse</span>
                    <div>{data.warehouseCode}</div>
                </div>
            }
            {data.trackingID &&
                <div className="content">
                    <span>Tracking No.</span>
                    <div>{data.trackingID}</div>
                </div>
            }
            {data.entries && <div className="details-header" onClick={onHide}>
                Entries
                <div className={`${hidden ? "active" : "inactive"}`}>
                    <Chevron />
                </div>
            </div>}
                {data.entries && data.entries.map((entry, i) => (
                    <div className={`details-container ${hidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`consignment-entry-${i}`}>
                        {entry.shippedQuantity &&
                            <div className="content">
                                <span>Quantity Shipped</span>
                                <div>{entry.shippedQuantity}</div>
                            </div>
                        }
                        {entry.entryProductCode &&
                            <div className="content">
                                <span>SKU</span>
                                <div>{entry.entryProductCode}</div>
                            </div>
                        }
                        {entry.entryProductName &&
                            <div className="content">
                                <span>Product</span>
                                <div>{entry.entryProductName}</div>
                            </div>
                        }
                        {entry.totalPrice > 0 &&
                            <div className="content">
                                <span>Total Price</span>
                                <div>£{entry.totalPrice}</div>
                            </div>
                        }
                        {entry.status &&
                            <div className="content">
                                <span>Status</span>
                                <div>{entry.status}</div>
                            </div>
                        }
                    </div> 
                ))}
        </div>
    );
};
export default Consignment;