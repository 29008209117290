import React, { useState } from "react";
import { ReactComponent as Chevron } from "Assets/Icons/Chevron_Right.svg";

const Package = ({ data }) => {
    const [hidden, setHidden] = useState(true);

    const onHide = () => {
        setHidden(hidden ? false : true);
    };

    return (
        <div className="container">
            {data.boxNumber &&
                <div className="content">
                    <span>Box Number</span>
                    <div>{data.boxNumber}</div>
                </div>
            }
            {data.packageNumber &&
                <div className="content">
                    <span>Tracking Number</span>
                    <div>{data.packageNumber}</div>
                </div>
            }
            {data.pckDispatchDate &&
                <div className="content">
                    <span>Shipping Date</span>
                    <div>{data.pckDispatchDate}</div>
                </div>
            }
            {data.pckExpectedDate &&
                <div className="content">
                    <span>Expected Deliv. Date</span>
                    <div>{data.pckExpectedDate}</div>
                </div>
            }
            {data.pckStatus &&
                <div className="content">
                    <span>Status</span>
                    <div>{data.pckStatus}</div>
                </div>
            }
            
            {data.listContentsBoxDetails && 
                <div className="details-header" onClick={onHide}> 
                    Items
                    <div className={`${hidden ? "active" : "inactive"}`}>
                        <Chevron />
                    </div>
                </div>
            }
            {data.listContentsBoxDetails && data.listContentsBoxDetails.map((entry, i) => (
                <div className={`details-container ${hidden ? "hidden" : ""} ${i % 2 ? "" : "staggered-details-container"}`} key={`package-entry-${i}`}>
                    {entry.skuBoxId &&
                        <div className="content">
                            <span>SKU</span>
                            <div>{entry.skuBoxId}</div>
                        </div>
                    }
                    <div className="content">
                        <span>Qty Dispatched</span>
                        <div>{entry.contentQtyDistpached}</div>
                    </div>
                    <div className="content">
                        <span>Qty Ordered</span>
                        <div>{entry.contentQtyOrdered}</div>
                    </div>
                </div> 
            ))}
        </div>
    );
};

export default Package;