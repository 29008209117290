import React, { useState } from "react";
import { URL } from "Constants/urls.js"
import getError from "Utils/errorHandling.js";

const Totals = ({ data }) => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const actualUnformattedDeliveryValue = data.deliveryCost.formattedValue.substr(1, data.deliveryCost.formattedValue.length);
    const onRefundDelivery = async (e) => {
        e.preventDefault();

        try {
            const payload = { code: data.code, amount: actualUnformattedDeliveryValue };
            const body = JSON.stringify(payload);
            const resp = await window.Gnatta.http.post(`${URL}/refund/delivery`, { body });
            if(resp.error) {
                setError(resp.error);
            } else {
                setError("");
                setSuccess("The delivery charge has been refunded");
            }
        } catch (err) {
            setError(getError(err));
        }
    }
    return (
        <div className="container">
            {error && 
                <div className="message-content">
                    <div className="error">{error}</div>
                </div>
            }
            {success && 
                <div className="message-content">
                    <div className="success">{success}</div>
                </div>
            }
            {data.subTotal && data.subTotal.formattedValue &&
                <div className="content">
                    <span>Subtotal</span>
                    <div>{data.subTotal.formattedValue}</div>
                </div>
            }
            {data.totalTax && data.totalTax.formattedValue &&
                <div className="content">
                    <span>Taxes</span>
                    <div>{data.totalTax.formattedValue}</div>
                </div>
            }
            {data.paymentCost !== null &&
                <div className="content">
                    <span>Payment Costs</span>
                    <div>{data.paymentCost}</div>
                </div>
            }
            {data.deliveryCost && data.deliveryCost.formattedValue &&
                <div className="content">
                    <span>Delivery</span>
                    <div>
                        {data.deliveryCost.formattedValue}
                        {!success && actualUnformattedDeliveryValue < 13 && <button onClick={onRefundDelivery}>Refund</button>}
                    </div>
                </div>
            }
            {data.orderDiscounts && data.orderDiscounts.formattedValue &&
                <div className="content">
                    <span>Discounts</span>
                    <div>{data.orderDiscounts.formattedValue}</div>
                </div>
            }
            {data.totalPrice && data.totalPrice.formattedValue &&
                <div className="content">
                    <span>Total</span>
                    <div>{data.totalPrice.formattedValue}</div>
                </div>
            }
        </div>
    );
};

export default Totals;