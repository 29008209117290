import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as EntryIcon } from "Assets/Icons/Entry.svg";

const Entry = ({index, data, onChange}) => {
    const [quantity, setQuantity] = useState(data.quantity);
    let entry = {
        name: data.name,
        code: data.code,
        entry: data.entry,
        quantity: data.quantity,
    };

    const handleModify = (target) => {
        if(target.name === "quantity") {
            if(target.value > entry.quantity || target.value < 0) {
                return;
                // can't refund more than those that were ordered
            }
            setQuantity(target.value);
            onChange(target.name, index, target.value);
        }
    };

    return (
        <div className={`${index % 2 ? "normal" : "staggered"}`}>
            <div className="name">
                <div className="icon">
                    <EntryIcon />
                </div>
                {data.name}
            </div>
            <div className="input-container">
                <input 
                    className="small"
                    name="quantity"
                    type="number" 
                    placeholder="Quantity..."
                    value={quantity}
                    onChange={((e, v) => handleModify(e.target))}
                />
                <span>&nbsp;   Max = {data.quantity}</span>
            </div>
        </div>
    );
};

Entry.propTypes = {
    data: PropTypes.shape({
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        entry: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        reason: PropTypes.string.isRequired,
        notes: PropTypes.string.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
};

export default Entry;