import React, { useState } from "react";
import PropTypes from "prop-types"; 
import Modal from "Components/Modal/index.js";
import Entry from "Components/OrderRefund/Entry";
import { URL } from "Constants/urls.js"
import getError from "Utils/errorHandling.js";
import "./styles.scss";

const OrderRefund = ({isActive, order, callback, entries, items}) => {
    const reasons = [
        {
            key: "RETURN_DC_RETURNED_OVER_28_DAYS_UNKNOWN_ORDER",
            value: "DC Returned Over 28 Days"
        },
        {
            key: "REFUND_GOODWILL",
            value: "Goodwill"
        },
        {
            key: "REFUND_WRONG_ITEM_DELIVERED_MISPICK",
            value: "Wrong Item Delivery"
        },
        {
            key: "REFUND_CARRIER_LOST_IN_TRANSIT",
            value: "Lost In Transit"
        },
        {
            key: "REFUND_LATE_DELIVERY",
            value: "Late Delivery"
        },
        {
            key: "REFUND_MISSING_ITEM",
            value: "Missing Item"
        },
        {
            key: "REFUND_DAMAGED_PRODUCT",
            value: "Damaged Product"
        },
        {
            key: "REFUND_ITEM_NOT_AS_PER_WEBSITE_DESCRIPTION",
            value: "Incorrect Website Description"
        },
        {
            key: "REFUND_MANUFACTURING_FAULT_QUALITY",
            value: "Manufacturing Fault"
        },
        {
            key: "REFUND_INCORRECT_ORDER_RECEIVED",
            value: "Incorrect Order Received"
        },
        {
            key: "REFUND_SHOWING_AS_DELIVERED_CUSTOMER_NOT_RECEIVED",
            value: "Delivered not Received"
        }
    ];
    const [notes, setNotes] = useState("");
    const [reason, setReason] = useState(reasons[0].key);
    let editables = [];
    let submitables = [];
    entries.map((entry) => editables.push({ name: entry.entryProductName, code: order, entry: items.find(x => x.product.code === entry.entryProductCode).entryNumber, quantity: items.find(x => x.product.code === entry.entryProductCode).quantity, reason: "", notes: "" }));

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [payload, setPayload] = useState(editables) 

    const onChange = (propertyName, index, value) => {
        let newArr = [...payload]; // copying the old datas array
        newArr[index][propertyName] = value; // replace e.target.value with whatever you want to change it to
        setPayload(newArr);
    };

    const payloadValid = () => {
        submitables = [];
        payload.forEach((entry) => {
            if(editables.indexOf(entry) === -1) {
                if(entry.quantity > 0) {
                    const submit = {...entry, reason, notes };
                    submitables.push(submit);
                }
            }
        });

        if(submitables.length > 0) {
            return true;
        }

        return false;
    };

    const create = async (e) => {
        e.preventDefault();

        if(payloadValid()) {
            try {
                const body = JSON.stringify(submitables);
                const response = await window.Gnatta.http.post(`${URL}/refund`, { body });
                const result = JSON.parse(response);
                if(result.error) {
                    setError(result.error);
                } else {
                    setError("");
                    setSuccess("Success! Your refund has been submitted, this form will close shortly.");

                    setTimeout(() => { 
                        callback();
                    }, 3999);
                }
            } catch(err) {
                setError(getError(err));
            }
        } else {
            setError("You must enter some details for at least one product")
        }
    };

    return (
        <div className="order-refund">
            <Modal callback={callback} show={isActive} header={"Refund Order"}>
                {error && 
                    <div className="error">{error}</div>
                }
                {success &&
                    <div className="success">{success}</div>
                }
                <div className="note">
                    You must enter a quantity greater than 0 for any products you wish to refund - those without will not be included when you submit the refund request.
                </div>
                <div className="main">
                    <div className="select-container">
                        <span className="custom-select">
                            <select name="action" value={reason} onChange={((e, v) => setReason(e.target.value))}>
                                {reasons.map((f) =>
                                    <option key={f.key} value={f.key}>{f.value}</option>
                                )}
                            </select>
                        </span>
                    </div>
                    <div className="input-container">
                        <input 
                            name="notes"
                            type="notes" 
                            placeholder="Notes..."
                            value={notes}
                            onChange={((e, v) => setNotes(e.target.value))}
                        />
                    </div>
                </div>
                {editables.map((entry, i) => <Entry data={entry} onChange={onChange} index={i} key={i} />)}
                <div className="button-container">
                    <button onClick={(e) => callback(e)}>Cancel</button>
                    <button onClick={(e) => create(e)}>Submit</button>
                </div>
            </Modal>
        </div>
    );
};

OrderRefund.propTypes = {
    callback: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    entries: PropTypes.arrayOf(
        PropTypes.object.isRequired,
    ).isRequired,
};

export default OrderRefund;