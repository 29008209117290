import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as Icon } from "Assets/Icons/Input.svg";
import Modal from "Components/Modal/index.js";
import { URL } from "Constants/urls.js"
import getError from "Utils/errorHandling.js";

const CreateTicket = ({isActive, order, callback}) => {
    const categories = ["Enquiry", "Problem", "Incident", "Complaint", "Fraud", "Note"];
    const interventions = ["Email", "Call"];
    const priorities = ["Low", "Medium", "High"];
    const reasons = ["Complaint", "Update", "First contact"];
    const [comment, setComment] = useState("");
    const [headline, setHeadline] = useState("");
    const [ticketCategory, setTicketCategory] = useState(categories[0]);
    const [ticketIntervention, setTicketIntervention] = useState(interventions[0]);
    const [ticketPriority, setTicketPriority] = useState(priorities[0]);
    const [ticketReason, setTicketReason] = useState(reasons[0]);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const create = async (e) => {
        e.preventDefault();

        if(comment && headline && ticketCategory && ticketIntervention && ticketPriority && ticketReason) {
            try {
                const payload = {
                    orderNumber: order,
                    comment,
                    headline,
                    ticketCategory,
                    ticketIntervention,
                    ticketPriority,
                    ticketReason
                };
                const body = JSON.stringify(payload);
                const response = await window.Gnatta.http.post(`${URL}/ticket`, { body });
                const result = JSON.parse(response);
                if(result.error) {
                    setError(result.error);
                } else {
                    setError("");
                    setSuccess("Success! Your ticket has been created, this form will close shortly.");

                    setTimeout(() => { 
                        callback();
                    }, 3999);
                }
            } catch(err) {
                setError(getError(err));
            }
        } else {
            setError("All fields are required")
        }
    };

    return (
        <Modal callback={callback} show={isActive} header={"Create Ticket"}>
            {error && 
                <div className="error">{error}</div>
            }
            {success &&
                <div className="success">{success}</div>
            }
            <div className="input-container-textarea">
                <Icon />
                <textarea
                    rows="3"
                    type="text"
                    placeholder="Comment..."
                    value={comment}
                    onChange={((e, v) => setComment(e.target.value))}
                />
            </div>
            <div className="input-container">
                <Icon />
                <input 
                    type="text" 
                    placeholder="Headline..."
                    value={headline}
                    onChange={((e, v) => setHeadline(e.target.value))}
                />
            </div>
            <div className="select-container">
                <label htmlFor="category">Category</label>
                <span className="custom-select">
                    <select id="category" value={ticketCategory} onChange={((e, v) => setTicketCategory(e.target.value))}>
                        {categories.map((f) =>
                            <option key={f} value={f}>{f}</option>
                        )}
                    </select>
                </span>
            </div>
            <div className="select-container">
                <label htmlFor="intervention">Intervention</label>
                <span className="custom-select">
                    <select id="intervention" value={ticketIntervention} onChange={((e, v) => setTicketIntervention(e.target.value))}>
                        {interventions.map((f) =>
                            <option key={f} value={f}>{f}</option>
                        )}
                    </select>
                </span>
            </div>
            <div className="select-container">
                <label htmlFor="priority">Priority</label>
                <span className="custom-select">
                    <select id="priority" value={ticketPriority} onChange={((e, v) => setTicketPriority(e.target.value))}>
                        {priorities.map((f) =>
                            <option key={f} value={f}>{f}</option>
                        )}
                    </select>
                </span>
            </div>
            <div className="select-container">
                <label htmlFor="reason">Reason</label>
                <span className="custom-select">
                    <select id="reason" value={ticketReason} onChange={((e, v) => setTicketReason(e.target.value))}>
                        {reasons.map((f) =>
                            <option key={f} value={f}>{f}</option>
                        )}
                    </select>
                </span>
            </div>
            <div className="button-container">
                <button onClick={(e) => callback(e)}>Cancel</button>
                <button onClick={(e) => create(e)}>Create</button>
            </div>
        </Modal>
    );
};

CreateTicket.propTypes = {
    callback: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
};

export default CreateTicket;